html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}


.carousel-indicators li {
  background-color: gray
}

.bg-light {
  background-color: #ec5500!important;
}

/* COUPON */

.coupon {
  border: 3px dashed #bcbcbc;
  border-radius: 10px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

.coupon #head {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 56px;
}

.coupon #footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#title .visible-xs {
  font-size: 12px;
}


.price{
  margin-left : 36px
}
.coupon #title img {
  font-size: 30px;
  height: 30px;
  margin-top: 5px;
}

@media screen and (max-width: 500px) {
  .coupon #title img {
    height: 15px;
  }
}

.coupon #title span {
  float: right;
  margin-top: 5px;
  font-weight: 700;
  text-transform: uppercase;
}

.coupon-img {
  width: 100%;
  margin-bottom: 15px;
  padding: 0;
}

.items {
  margin: 12px 0;
  margin-top: 4px;
  font-size: 0.9em
}

.usd,
.cents {
  font-size: 20px;
}

.number {
  font-size: 40px;
  font-weight: 700;
}

sup {
  top: -15px;
}

#business-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
}

#business-info ul li {
  display: inline;
  text-align: center;
}

#business-info ul li span {
  text-decoration: none;
  padding: .2em 1em;
}

#business-info ul li span i {
  padding-right: 5px;
}

.disclosure {
  padding-top: 15px;
  font-size: 11px;
  color: #bcbcbc;
  text-align: center;
}

.coupon-code {
  color: #333333;
  font-size: 11px;
}

.exp {
  color: #f34235;
}

.print {
  font-size: 14px;
  float: right;
}

a:hover {
  text-decoration: none
}

/* QUOTE */

.quote-card {
  background: #fff;
  color: #222222;
  padding: 20px;
  padding-left: 50px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.12);
  position: relative;
  overflow: hidden;
  min-height: 120px;
}
.quote-card p {
  font-size: 22px;
  line-height: 1.5;
  margin: 0;
  max-width: 80%;
}
.quote-card cite {
  font-size: 16px;
  margin-top: 10px;
  display: block;
  font-weight: 200;
  opacity: 0.8;
}
.quote-card:before {
  font-family: Georgia, serif;
  content: "\201C";
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 5em;
  color: rgba(238, 238, 238, 0.8);
  font-weight: normal;
}
.quote-card:after {
  font-family: Georgia, serif;
  content: "\201D";
  position: absolute;
  bottom: -110px;
  line-height: 100px;
  right: -32px;
  font-size: 25em;
  color: rgba(238, 238, 238, 0.8);
  font-weight: normal;
}
@media (max-width: 640px) {
  .quote-card:after {
    font-size: 22em;
    right: -25px;
  }
}
.quote-card.blue-card {
  background: #0078FF;
  color: #ffffff;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.12), 0 2px 4px rgba(34, 34, 34, 0.24);
}
.quote-card.blue-card:before, .quote-card.blue-card:after {
  color: #5FAAFF;
}
.quote-card.green-card {
  background: #00970B;
  color: #ffffff;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.12), 0 2px 4px rgba(34, 34, 34, 0.24);
}
.quote-card.green-card:before, .quote-card.green-card:after {
  color:#59E063 ;
}

.quote-card.red-card {
  background: #F61E32;
  color: #ffffff;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.12), 0 2px 4px rgba(34, 34, 34, 0.24);
}
.quote-card.red-card:before, .quote-card.red-card:after {
  color:#F65665 ;
}

.quote-card.yellow-card {
  background: #F9A825;
  color: #222222;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.12), 0 2px 4px rgba(34, 34, 34, 0.24);
}
.quote-card.yellow-card:before, .quote-card.yellow-card:after {
  color: #FBC02D;
}
